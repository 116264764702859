$(document).ready(function() {
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  // RESIZE MAP
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  /*
  if (window.matchMedia("(max-width: 1024px)").matches) {
  $(function() {
    var translateXr = 0, translateYr = 0;
    $(".map__image--mobile").swipe( {
      //Generic swipe handler for all directions
      swipe:function(event, direction, distance, duration, phase, fingerCount, fingerData) {
        var t = $(this).attr('style');
        t = t.replace('transform: translate(', '');
        t = t.substr(0, t.indexOf(')'));
        t2 = t.split(', ');
        var transX, transY;
        transX = t2[0].replace('px', '');
        transY = t2[1].replace('px', '');
        transX = Number(transX);
        transY = Number(transY);

        translateXr = transX;
        translateYr = transY;
      },
      threshold: 1,
      swipeStatus:function(event, direction, distance, duration, phase, fingerCount, fingerData) {
        var dimensions = document.getElementById("map").getBoundingClientRect();
        var maxTranslateX = (dimensions.width - $(".map__image").width())/2;
        var maxTranslateY = (Math.ceil(dimensions.height) - $(".map__image").height())/2;
        var translateX = fingerData[0].end.x - fingerData[0].start.x;
        var translateY = fingerData[0].end.y - fingerData[0].start.y;
        translateX += translateXr;
        translateY += translateYr;

        if ( Math.abs(translateX) > maxTranslateX) {
          translateX = (translateX > 0) ? maxTranslateX: -maxTranslateX;
        }

        if (Math.abs(translateY) > maxTranslateY ){
          translateY = (translateY > 0) ? maxTranslateY: -maxTranslateY;
        }

        $(this).css({transform: "translate("
        + translateX +"px,"
        + translateY +"px) scale("+sc+")"});
      }
    });
  });
  var translateX = 0, translateY = 0;
  var scale = 1;
  var sc = scale;
  $(".resize-plus").on("click", function() {
    sc+=0.2;
    $(this).parent().siblings().find(".map__image--mobile").css({transform: "translate(" + translateX +"px," + translateY +"px) scale("+sc+")"});
  });
  $(".resize-minus").on("click", function() {
    if (sc > scale) {
      sc-=0.2;
      $(this).parent().siblings().find(".map__image--mobile").css({transform: "translate("  + translateX +"px,"  + translateY +"px) scale("+sc+")"});
    }
  });
  }
*/
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  // LIGHT GALLERY
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX

  $("#lightgallery1, #lightgallery2, #lightgallery3, #lightgallery4, #lightgallery5, .lightgallery6").lightGallery({
    thumbnail:true
  });
  $("#lightgallery6").lightGallery({
    thumbnail:true,
    selector: '.lightgallery6'
  });

  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  // Map changing on click
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  if (window.matchMedia("(min-width: 1024px)").matches) {
  $(window).on("load", function(){
    var imageHeight = Math.ceil($("#map-change").height());
    $("#map-change-container").height(imageHeight);
  });
  $(".list__element").on("click", function() {
    var src = $("#map-change").attr('srcset');
    $("#map-change").attr('srcset', 'images/mapa-okolica-' + $(this).attr('id') + '.jpg');
    $("#map-change2").attr('srcset', 'images/mapa-okolica-' + $(this).attr('id') + '.jpg');
    $(".list__element.neighbourhood__element").removeClass("neighbourhood__active");
    $(this).addClass("neighbourhood__active");
  });
  }

  if (window.matchMedia("(max-width: 767px)").matches) {
    $(".inside__content3").append($(".inside__background"));
  }
  if (window.matchMedia("(max-width: 991px)").matches) {
    $(".projection__js").append($(".projection__map"));
  }
  $(".projection__data").hide();
  $("#parter-lewy-box").show();

  $(window).on("load", function(){
    var imageHeight2 = Math.ceil($("#map-change-projection").height());
    $(".projection__map").height(imageHeight2);
  })
  $(".p-nav-element").on("click", function() {
    var src = $("#map-change-projection").attr('srcset');
    $("#map-change-projection").attr('srcset', 'images/Osiedle-Klonowe-rzut-mieszkania-' + $(this).attr('id') + '.svg');
    $(".projection__data").hide();
    var id = $(this).attr('id');
    $("#"+ id + '-box').show();
  });
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  // MENU MAP ROLL DOWN
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  if (window.matchMedia("(max-width: 991px)").matches) {
  $(".after-arrow").on("click", function() {
    $(this).siblings().toggleClass("scheme__list--hide-active");
    $(this).toggleClass("after-arrow-active");
    if (!$(this).hasClass("after-arrow-active")) {
      if ($(".scheme__list--mobile.neighbourhood__scroll").offset().top > 0)
        $("html, body").stop().animate({
          scrollTop: $(".scheme__list--mobile.neighbourhood__scroll").offset().top - $(".header").height() - 10
        }, 500);
      else {
        $("html, body").stop().animate({
          scrollTop: $(".scheme__list.neighbourhood__scroll").offset().top - $(".header").height() - 90
        }, 500);
      }
    }
  });
  }
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  // TABLE DROPDOWN
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX

  $(".navigation__dropdown").hide();
  $(".navigation__button").on("click", function() {
    $(this).toggleClass("navigation__button--open");
    $(".navigation__dropdown").slideUp();
    $(".navigation__button").removeClass("navigation__button--active");
    if ($(this).hasClass("navigation__button--open")) {
      $(this).next().slideDown();
      $(this).addClass("navigation__button--active");
    }
    if (!$(this).hasClass("navigation__button--open")) {
      $(this).next().slideUp();
    }
  });


  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  // HEADER HAMBURGER
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  $("#header__hamburger").on("click", function() {
    $("#header").toggleClass("header-active");
  });
  $(".nav__link").on("click", function() {
    $("#header").removeClass("header-active");
  });
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  // HEADER SOCIALS text
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  if (window.matchMedia("(min-width: 1150px)").matches) {
  var $trigger = $(".socials__images a");
  var $content = $(".socials__description");
  $trigger.on("mouseenter", function() {
    var index = $(this).index();
    $content.children().hide();
    $content.children().eq(index).fadeIn();
  });
  $trigger.on("mouseleave", function() {
    $content.children().hide();
  });
  }
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  // SCROLL
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  $(".header__logo").on("click", function(e) {
    e.preventDefault();
    $("html, body").stop().animate({
      scrollTop: 0
    }, 500);
  });
  function onScroll() {
    var scrollPos = $(document).scrollTop();
    $(".nav__link").each(function() {
      var currLink = $(this);
      var refElement = $(currLink.attr("href"));
      if (refElement.offset().top - $(".header").height() - 10<= scrollPos  && refElement.offset().top + refElement.outerHeight() - $(".header").height()-10 > scrollPos ) {
        $(".nav__link").removeClass("active");
        currLink.addClass("active");
      } else {
        currLink.removeClass("active");
      }
    });
  }
  $(document).on("scroll", onScroll);
  // smoothscroll
  $(".nav__link").on("click", function(e) {
    e.preventDefault();
    $(document).off("scroll");
    $(".nav__link").each(function() {
      $(this).removeClass("active");
    });
    $(this).addClass("active");

    var target = this.hash;
    var $target = $(target);
    $("html, body").stop().animate({
      scrollTop: $target.offset().top - $(".header").height() + 2
    }, 500, "swing", function() {
      window.location.hash = target;
      $(document).on("scroll", onScroll);
    });
  });

  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  // STANDART ROLL DOWN
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  if (window.matchMedia("(max-width: 991px)").matches) {
  var standardHeight = $(".standard__row").height();
  $(".standard__box").hide();
  $(".standard__box:lt(3)").show();
  var standardHeightLimited = $(".standard__row").height();

  $(".roll-down").on("click", function() {
    $(".standard__row").toggleClass("standard__row--active");
    $(this).toggleClass("roll-down--active");
    $(".standard__box").show();
    if ($(".standard__row").hasClass("standard__row--active")) {
      $(".standard__row").height(standardHeight);
    } else {
      $(".standard__row").height(standardHeightLimited);
      $("html, body").stop().animate({
        scrollTop: $(".standard__row").offset().top - $(".header").height() - 30
      }, 500);
    }
  });
  }

  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  // SLIDER
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX

  var mainSlider = new Swiper(".slider.swiper-container", {
    direction: "horizontal",
    loop: true,
    autoplay: 3500,
    speed: 500,
    slidesPerView: 1,
    nextButton: '.swiper-button-next',
    prevButton: '.swiper-button-prev'
  });
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  // SVG
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  $("img.svg").each(function() {
    var $img = $(this);
    var imgID = $img.attr("id");
    var imgClass = $img.attr("class");
    var imgURL = $img.attr("src");

    $.get(imgURL, function(data) {
          // Get the SVG tag, ignore the rest
      var $svg = $(data).find("svg");

          // Add replaced image"s ID to the new SVG
      if (typeof imgID !== "undefined") {
        $svg = $svg.attr("id", imgID);
      }
          // Add replaced image"s classes to the new SVG
      if (typeof imgClass !== "undefined") {
        $svg = $svg.attr("class", imgClass + " replaced-svg");
      }

          // Remove any invalid XML tags as per http://validator.w3.org
      $svg = $svg.removeAttr("xmlns:a");

          // Replace image with new SVG
      $img.replaceWith($svg);
    },  "xml");
  });
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  // VALIDATION
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  $(function() {
    $(".contact__form").validate({
      rules: {
        email: {
          required: true,
          email: true
        },
        name: "required",
        message: "required",
        password: "required"
      },
      messages: {
        email: {
          required: "Wpisz adres email",
          email: "Wpisz poprawny adres email"
        },
        name: "Wpisz swoje imię i nazwisko",
        message: "Wpisz wiadomość",
        password: "Wpisz poprawny kod"
      },
      errorPlacement: function(error, element) {
        if (element.attr("name") == "name" )
          error.insertAfter(".form__name");
        else if  (element.attr("name") == "email" )
          error.insertAfter(".form__email");
        else if  (element.attr("name") == "message" )
          error.insertAfter(".form__message");
        else if  (element.attr("name") == "password" )
          error.insertAfter(".form__password");
        else
          error.insertAfter(element);
      }
      /*errorPlacement: function(error, element) {
        element.attr("placeholder", error.text()).addClass("input");
        // error.css("color", "red");
        // element.css("color", "red");
      }*/
    });
  });
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  // SITE RESIZE
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  $(".full__site").on("click", function(e) {
    e.preventDefault();
    $("meta[name=viewport]").attr("content", "width=1520");
  });
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  // TABLE SLIDER
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  if (window.matchMedia("(max-width: 767px)").matches) {
  var elementWidth = (window.innerWidth - 35)/3;
  var $static = $(".categories__budynek");
  var $mobile = $(".categories__slide");
  $static.outerWidth(elementWidth);
  $mobile.outerWidth(elementWidth);

  var maxTranslateSlide = elementWidth*4;
  var translateSlide = 0;
  var tS = translateSlide;
  $("#slide-left").on("click", function(){
    tS += elementWidth * 2;
    if(tS > Math.abs(maxTranslateSlide)) {
      tS = maxTranslateSlide;
      $mobile.css("transform","translateX("+-tS+"px)");
    } else {
      $mobile.css("transform","translateX("+-tS+"px)");
    }
  });

  $("#slide-right").on("click", function(){
    tS -= elementWidth * 2;
    if(tS < 0) {
      tS = 0;
      $mobile.css("transform","translateX("+-tS+"px)");
    } else {
      $mobile.css("transform","translateX("+-tS+"px)");
    }
  });
  }


  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  // FORM ROLL
  // XXXXXXXXXXXXXXXXXXXXXXXXXXX
  if (window.matchMedia("(max-width: 991px)").matches) {
    $(".contact__form").hide();
    $(".form__roll").on("click", function(e) {
      e.preventDefault();
      $(".contact__form").slideToggle();
      $(this).toggleClass("form__roll--active");
      if ($(this).hasClass("form__roll--active")) {
        $(this).text("MNIEJ");
      } else {
        $(this).text("FORMULARZ KONTAKTOWY");
      }
    });
  }
});
